<template>
  <!-- <div id="PatientMHistory" class="w-full"> -->
  <div>
    <!-- class="vx-row" -->
    <!-- <feather-icon
        @click="back()"
        :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
        style="width: 30px; cursor: pointer"
      >
      </feather-icon>
      <p class="text-xl">
        {{ $t("PatientMedicalHistory") }}
      </p>
    </div> -->
    <div class="ml-5 mr-10">
      <div class="vx-row">
        <feather-icon
          @click="back()"
          :icon="$vs.rtl ? 'ChevronRightIcon' : 'ChevronLeftIcon'"
          style="width: 30px; cursor: pointer"
        >
        </feather-icon>
        <h2 style="color: black">{{ $t("socialhistory") }}</h2>
      </div>

      <div class="w-full ml-5 mr-5">
        <!-- birth  -->
        <div div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2 w-full">{{ $t("BirthDay") }}</b>
          <datepicker
            :placeholder="$t('BirthDay')"
            v-model="Model.Patient.BirthDate"
            class="vx-col lg:w-3/12"
          ></datepicker>

          <!-- gender  -->
          <b class="vx-col xl:w-1/12 m-2 w-full">{{ $t("Gender") }}</b>
          <v-select
            :dir="$vs.rtl ? 'rtl' : 'ltr'"
            label="Name"
            class="vx-col xl:w-4/12"
            v-model="Model.Patient.GenderID"
            :options="genders"
            :reduce="(ID) => ID.ID"
            :disabled="true"
          />
        </div>
        <!-- MaritalStatus -->
        <div div class="vx-row mt-5">
          <b class="vx-col xl:w-3/12 text-left m-2 sm:w-full w-full">{{
            $t("MaritalStatus")
          }}</b>

          <vs-radio
            class="vx-col lg:w-1/7 xm:w-1/2"
            v-model="Model.Patient.MaritalStatusID"
            :vs-value="1"
             @change="trueFlag=false"
            :disabled="false"
            >{{ $t("Single") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7 sm:w-1/2"
            v-model="Model.Patient.MaritalStatusID"
            :vs-value="4"
            :disabled="true"
            >{{ $t("Married") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7 sm:w-1/2"
            v-model="Model.Patient.MaritalStatusID"
            :vs-value="2"
            :disabled="true"
            >{{ $t("Divorced") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7 sm:w-1/2"
            v-model="Model.Patient.MaritalStatusID"

            :vs-value="3"
            :disabled="true"
            >{{ $t("Widowed") }}</vs-radio
          >
        </div>
        <!-- HaveChildren -->
        <div v-if="Model.Patient.MaritalStatusID !=1" >
        <div
          class="vx-row mt-5"
          v-if="(Model.Patient.NumOfChildren != null) && (Model.Patient.NumOfChildren > 0)"
        >
          <b class="xl:w-3/12 text-left m-2">{{ $t("HaveChildren") }}</b>
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="trueFlag"
            vs-name="trueFlag"
            :vs-value="true"
            :disabled="true"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="trueFlag"
            vs-name="trueFlag"
            :vs-value="false"
            :disabled="true"
            >{{ $t("no") }}</vs-radio
          >

          <p class="vx-col lg:w-1/7 sm:w-1/2">{{ $t("Ifyeshowmany") }}:</p>

          <vs-input
            class="vx-col lg:w-1/8 sm:w-1/2"
            :disabled="true"
            v-model="Model.Patient.NumOfChildren"
            :placeholder="$t('NumOfChildren')"
          />

          <p class="vx-col lg:w-1/8 w-full">{{ $t("Age") }}:</p>
          <vs-input
            class="vx-col lg:w-1/8 sm:w-1/4"
            v-model="Model.Patient.AgeOfChildren"
            :placeholder="$t('5,8,6,..')"
          />
        </div>
        <!-- HaveChildren -->
        <div  class="vx-row mt-5" v-else>
          <b class="xl:w-3/12 text-left m-2">{{ $t("HaveChildren") }}</b>
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="falseFlag"
            vs-name="falseFlag"
            :vs-value="true"
            :disabled="true"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="falseFlag"
            vs-name="falseFlag"
            :vs-value="false"
            :disabled="true"
            >{{ $t("no") }}</vs-radio
          >
        </div>
      </div>
        <!-- Internationalinsurance -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2 w-full">
            {{ $t("Internationalinsurance") }}</b
          >
          <vs-radio
            class="vx-col lg:w-1/7 sm:w-1/2"
            v-model="Model.IsHaveInternationalInsurance"
            :disabled="true"
            vs-name="Internationalinsurance"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            @change="
              Model.InsuranceCompanyID = null;
              Model.InsuranceCompanyName = null;
            "
            class="vx-col lg:w-1/7"
            v-model="Model.IsHaveInternationalInsurance"
            vs-name="Internationalinsurance"
            :vs-value="false"
            :disabled="true"
            >{{ $t("no") }}</vs-radio
          >
          <p class="vx-col lg:w-1/7 w-full">{{ $t("Insurancecompanyname") }}</p>
          <vs-select
            :disabled="!Model.IsHaveInternationalInsurance"
            class="vx-col lg:w-1/7"
            v-model="Model.InsuranceCompanyID"
          >
            <vs-select-item
              :value="null"
              :text="$t('OtherCompany')"
              style="background-color: #352566"
              :disabled="true"
            />
            <vs-select-item
              :key="index"
              :value="item.ID"
              :text="item.Name"
              v-for="(item, index) in companies"
              :disabled="true"
            />
          </vs-select>

          <vs-input
            v-if="Model.InsuranceCompanyID == null && Model.IsHaveInternationalInsurance"
            class="vx-col lg:w-1/7"
            vs-name="Insurancecompanyname"
            v-model="Model.InsuranceCompanyName"
            :placeholder="$t('companyname')"
            :disabled="true"
          />
        </div>

        <!-- Areyouasmoker -->
        <div div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2 w-full">
            <p>{{ $t("Areyouasmoker") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="Model.IsSmoker"
            :vs-value="true"
            :disabled="true"
            vs-name="Areyouasmoker"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            v-model="Model.IsSmoker"
            vs-name="Areyouasmoker"
            :vs-value="false"
            :disabled="true"
            >{{ $t("no") }}</vs-radio
          >
          <div class="vx-row ml-4" v-if="Model.IsSmoker == true">
            <p>{{ $t("Level") }}:</p>
            <div class="vx-col" :disabled="!Model.IsSmoker">
              <vs-radio
                class="m-1"
                :disabled="true"
                vs-name="SmokeDegreeID"
                v-model="Model.SmokeDegreeID"
                :vs-value="1"
                >{{ $t("Heavy") }}</vs-radio
              >
              <vs-radio
                class="m-1"
                :disabled="true"
                vs-name="SmokeDegreeID"
                v-model="Model.SmokeDegreeID"
                :vs-value="2"
                >{{ $t("Moderate") }}</vs-radio
              >
              <vs-radio
                class="m-1"
                :disabled="true"
                vs-name="SmokeDegreeID"
                v-model="Model.SmokeDegreeID"
                :vs-value="3"
                >{{ $t("Occasionaly") }}</vs-radio
              >
            </div>
          </div>
        </div>

        <!-- section Clarificationofeachcriteria6cigarettesadayconsideredasmoderate -->
        <h4 class="mt-5">
          <p>
            {{ $t("Clarificationofeachcriteria6cigarettesadayconsideredasmoderate") }}
          </p>
        </h4>

        <!-- Doyoudrinkalcohol -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2">
            <p>{{ $t("Doyoudrinkalcohol") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/5 w-full"
            vs-name="IsDrinkAlcoholCurrently"
            v-model="Model.IsDrinkAlcoholCurrently"
            :vs-value="true"
            :disabled="true"
            >{{ $t("Yescurrently") }}</vs-radio
          >
          <vs-radio
            vs-name="IsDrinkAlcoholCurrently"
            class="vx-col lg:w-1/5 w-full"
            v-model="Model.IsDrinkAlcoholRarely"
            :disabled="true"
            :vs-value="true"
            >{{ $t("Yesbutoccasionallyrarely") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/5 w-full"
            vs-name="IsDrinkAlcoholCurrently"
            :disabled="true"
            v-model="Model.NoIquit"
            :vs-value="true"
            >{{ $t("no") }}</vs-radio
          >
        </div>

        <!-- Howmanydrinksperday -->
        <div class="vx-row mt-5">
          <p class="xl:w-3/12 text-left m-2 w-full">{{ $t("Howmanydrinksperday") }}:</p>
          <vs-input
            v-model="Model.ManyDrinksAday"
            class="flex-1"
            :placeholder="$t('drinksperday')"
            vs-name="IsDrinkAlcoholCurrently"
            :disabled="true"
          ></vs-input>
        </div>

        <!-- Areyoudiagnosedwithloworhighpressure -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 text-left m-2 w-full">
            <p>{{ $t("Areyoudiagnosedwithloworhighpressure") }}</p>
          </b>
          <vs-radio
            class="vx-col lg:w-1/7 w-full"
            v-model="Model.IsHavePresssure"
            :vs-value="true"
            :disabled="true"
            vs-name="IsHavePresssure"
            >{{ $t("Highpressure") }}</vs-radio
          >
          <div class="vx-col lg:w-1/7 sm:w-0"></div>
          <vs-radio
            class="vx-col lg:w-1/5 w-full"
            v-model="Model.IsLowPressure"
            vs-value="15"
            :disabled="true"
            vs-name="IsHavePresssure"
            >{{ $t("Lowpressure") }}</vs-radio
          >

          <vs-radio
            class="vx-col lg:w-1/5 w-full"
            v-model="Model.IsHavePresssure"
            :vs-value="false"
            :disabled="true"
            vs-name="IsHavePresssure"
            >{{ $t("no") }}</vs-radio
          >
        </div>

        <!-- Areyouasthmatic -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/4 text-left m-2">
            <p>{{ $t("Areyouasthmatic") }}</p>
          </b>
          <vs-radio
            class="vx-col lg:w-1/7 w-full"
            vs-name="Areyouasthmatic"
            v-model="Model.IsHaveAsthmatic"
            :vs-value="true"
            :disabled="true"
            >{{ $t("yes") }}</vs-radio
          >
          <vs-radio
            class="vx-col lg:w-1/7 w-full"
            v-model="Model.IsHaveAsthmatic"
            :vs-value="false"
            :disabled="true"
            vs-name="Areyouasthmatic"
            >{{ $t("no") }}</vs-radio
          >
          <p class="vx-col lg:w-1/5 mt-3">{{ $t("Ifyesdescripe") }}:</p>
          <vs-input
            class="vx-col lg:w-1/5"
            v-model="Model.DiseaseNote"
            :disabled="true"
            :placeholder="$t('DiseaseNote')"
          ></vs-input>
        </div>

        <!-- Anycancerdiagnosishistoryinyourfamily -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("Anycancerdiagnosishistoryinyourfamily") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="ISHaveCancerHistoryinFamily"
            v-model="Model.ISHaveCancerHistoryinFamily"
            :disabled="true"
            :vs-value="true"
            >{{ $t("yes") }}</vs-radio
          >

          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="ISHaveCancerHistoryinFamily"
            v-model="Model.ISHaveCancerHistoryinFamily"
            :disabled="true"
            :vs-value="false"
            >{{ $t("no") }}</vs-radio
          >
          <p class="vx-col lg:w-1/5 mt-3">{{ $t("Ifyesdescripe") }}:</p>
          <vs-input
            class="vx-col lg:w-1/5"
            v-model="Model.CancerHistoryinFamilyNote"
            :disabled="true"
            :placeholder="$t('CancerHistoryinFamilyNote')"
          >
          </vs-input>
        </div>

        <!-- HaveDisease -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("HaveDisease") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveDisease"
            v-model="Model.IsHaveDisease"
            :vs-value="true"
            :disabled="true"
            >{{ $t("yes") }}</vs-radio
          >

          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveDisease"
            v-model="Model.IsHaveDisease"
            :vs-value="false"
            :disabled="true"
            >{{ $t("no") }}</vs-radio
          >
          <p class="vx-col lg:w-1/5 mt-3">{{ $t("Ifyesdescripe") }}:</p>
          <vs-input
            class="vx-col lg:w-1/5"
            :disabled="true"
            v-model="Model.DiseaseText"
            :placeholder="$t('DiseaseText')"
          >
          </vs-input>
        </div>

        <!-- HaveAids -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("HaveAids") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveAids"
            v-model="Model.ISHaveAids"
            :vs-value="true"
            :disabled="true"
            >{{ $t("yes") }}</vs-radio
          >

          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveAids"
            v-model="Model.ISHaveAids"
            :vs-value="false"
            :disabled="true"
            >{{ $t("no") }}</vs-radio
          >
          <p class="vx-col lg:w-1/5 mt-3 w-full">{{ $t("Ifyeswhen") }}:</p>
          <datepicker
            :placeholder="$t('AidsDate')"
            v-model="Model.AidsDate"
            :disabled="true"
            class="vx-col lg:w-1/5 w-full"
          ></datepicker>
        </div>

        <!-- HaveHeartProblem -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("HaveHeartProblem") }}</p></b
          >
          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveHeartProblem"
            v-model="Model.ISHaveHeartProblem"
            :vs-value="true"
            :disabled="true"
            >{{ $t("yes") }}</vs-radio
          >

          <vs-radio
            class="vx-col lg:w-1/7"
            vs-name="HaveHeartProblem"
            v-model="Model.ISHaveHeartProblem"
            :vs-value="false"
            :disabled="true"
            >{{ $t("no") }}</vs-radio
          >
        </div>

        <!-- LastBloodAnalysis -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("LastBloodAnalysis") }}</p></b
          >

          <p>{{ Model.LastBloodAnalysisDate | formatDate() }}</p>
        </div>

        <!-- Allergies -->
        <div class="vx-row mt-5">
          <b class="xl:w-3/12 sm:w-1/2 text-left m-2">
            <p>{{ $t("Allergies") }}</p></b
          >

          <vs-input
            class="vx-col lg:w-1/5"
            v-model="Model.Allergies"
            :placeholder="$t('Allergies')"
            :disabled="true"
          >
          </vs-input>
        </div>

        <!-- Haveyougonethroughanysurgerybefore -->
        <div class="vx-row">
          <vs-card class="w-full lg:w-2/5 m-5">
            <div class="vx-row mt-5">
              <b class="lg:w-1/2 text-left ml-5 mr-5">
                <p>{{ $t("Haveyougonethroughanysurgerybefore") }}</p></b
              >
              <vs-radio
                class="vx-col lg:w-1/5"
                vs-name="IsSurgeryBefore"
                v-model="Model.IsSurgeryBefore"
                :vs-value="true"
                :disabled="true"
                >{{ $t("yes") }}</vs-radio
              >

              <vs-radio
                class="vx-col lg:w-1/5"
                vs-name="IsSurgeryBefore"
                v-model="Model.IsSurgeryBefore"
                :vs-value="false"
                :disabled="true"
                >{{ $t("no") }}</vs-radio
              >
            </div>

            <div
              style="text-decoration-line: underline"
              v-if="
                Model != null &&
                Model.PatientMedicalHistoryPastSurgeries != undefined &&
                Model.PatientMedicalHistoryPastSurgeries.length > 0
              "
            >
              <div
                v-for="item in Model.PatientMedicalHistoryPastSurgeries"
                :key="item.ID"
              >
                <div class="vx-row m-5">
                  <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                    {{ item.SurgeryName }}
                  </h2>
                </div>
              </div>
            </div>
          </vs-card>

          <vs-card class="w-full lg:w-2/5 m-5">
            <b class="lg:w-1/2 text-left ml-5 mr-5">
              <p>{{ $t("Medication") }}</p></b
            >

            <div
              style="text-decoration-line: underline"
              v-if="
                Model != null &&
                Model.PatientMedicalHistoryMedications != undefined &&
                Model.PatientMedicalHistoryMedications.length > 0
              "
            >
              <div v-for="item in Model.PatientMedicalHistoryMedications" :key="item.ID">
                <div class="vx-row mt-5">
                  <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                    {{ item.MedicineName }}
                  </h2>
                  <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                    {{ item.Dose }}
                  </h2>
                  <h2 class="vx-col lg:w-1/4" style="color: #454a62; font-size: 19px">
                    {{ item.DurationInDay }}
                  </h2>
                </div>
              </div>
            </div>
          </vs-card>
          -
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import modulePatient from "@/store/Patient/modulePatient.js";
import { domain } from "@/gloabelConstant.js";
import moduleGender from "@/store/settings/gender/moduleGender.js";
import Datepicker from "vuejs-datepicker";
import moduleInsuranceCompany from "@/store/settings/insuranceCompany/moduleInsuranceCompany.js";

export default {
  data() {
    return {
      EnglishLan: false,
      baseURL: domain,
      Model: [],
      trueFlag: true,
      falseFlag: false,
    };
  },
  components: {
    Datepicker,
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
  },
  created() {
    if (!moduleInsuranceCompany.isRegistered) {
      this.$store.registerModule("InsuranceCompanyList", moduleInsuranceCompany);
      moduleInsuranceCompany.isRegistered = true;
    }

    this.$store.dispatch("InsuranceCompanyList/GetAllInsuranceCompanies");
    this.EnglishLan =
      localStorage.getItem("SaveLang") == "en" || localStorage.getItem("SaveLang") == null
        ? true
        : false;

    if (!modulePatient.isRegistered) {
      this.$store.registerModule("patientList", modulePatient);
      modulePatient.isRegistered = true;
    }

    if (!moduleGender.isRegistered) {
      this.$store.registerModule("GenderList", moduleGender);
      moduleGender.isRegistered = true;
    }
    this.$store.dispatch("GenderList/GetAllGenders");
    debugger;
    var PatientID = this.$route.params.ID;
    this.$store
      .dispatch("patientList/GetPatientMedicalHoistory", PatientID)
      .then((res) => {
        if (res.status == 200) {
          debugger;
          this.Model = res.data.Data;
          if (this.Model.Patient.BirthDate < "01-01-1950") {
            this.Model.Patient.BirthDate = null;
          }

          debugger;
        }
      })
      .catch(() => {
        window.showError();
      });
  },
  computed: {
    genders() {
      return this.$store.state.GenderList.genders;
    },
     companies() {
      return this.$store.state.InsuranceCompanyList.InsuranceCompanies;
    },
  },
};
</script>
<style>
#PatientMHistory table {
  border-collapse: collapse;
  border-radius: 2em;
  overflow: hidden;
  background-color: transparent;
}
#PatientMHistory th,
#PatientMHistory tr,
#PatientMHistory td {
  border: none;
  border-collapse: collapse;
  background-color: transparent;
}
#PatientMHistory .mycard .vx-card {
  /* padding: 0px !important; */
  background: unset;
}
#PatientMHistory .login-tabs-container {
  min-height: 505px;
}
#PatientMHistory .con-tab {
  padding-bottom: 10px;
}

#PatientMHistory .con-slot-tabs {
  margin-top: 1rem;
}
.background {
  color: rgb(255, 9, 9);
}

element.style {
  background: none;
}
#PatientMHistory .vs-button {
  display: inline;
  background-color: transparent !important;
  color: gray;
  width: 5rem !important;
}
#PatientMHistory .taskDoctor {
  background-color: transparent;
}
#PatientMHistory tr {
  height: 3.5rem;
}
#PatientMHistory .stack-top {
  z-index: 9;
  border-radius: 5px;
  background: #202020;
}
#PatientMHistory .head {
  color: white;
  text-align: center;
  margin-top: 20px;
}
[dir] #PatientMHistory th,
[dir] #PatientMHistory td {
  border-bottom: 1px solid #cfcfcf !important;
}
</style>
